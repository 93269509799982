








































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

import {
  QUANTITY_PICKER_COMPONENT_CONFIG_MAP,
  QUANTITY_PICKER_COMPONENT_KEY, QuantityPickerComponentConfig
} from './QuantityPicker.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { logger } from '../../../../support'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<QuantityPicker>({
  name: 'QuantityPicker',
  created () {
    this.config = this.getComponentConfig(
      QUANTITY_PICKER_COMPONENT_KEY,
      { ...QUANTITY_PICKER_COMPONENT_CONFIG_MAP }
    )
  }
})
export class QuantityPicker extends Mixins(Vue, StructureConfigurable) {
  @Prop({ required: false, type: Number, default: 1 })
  public counter!: number

  @Prop({ required: false, type: String, default: null })
  public id!: string | null

  @Prop({ required: false, type: Boolean, default: true })
  public isAvailable!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  public isDisabled!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ required: false, type: Number })
  public maxQty!: number

  @Prop({ required: false, type: Number })
  public minQty!: number

  @Prop({ required: false, type: Number })
  public qty!: number

  protected config!: QuantityPickerComponentConfig

  public get canAdd (): boolean {
    return this.isLoading || this.isDisabled || this.qty >= this.maxQty
  }

  public get canSubtract (): boolean {
    return this.isLoading || this.isDisabled || this.qty <= 1
  }

  public get configMinusIconName (): string {
    if (!this.config) {
      logger(
        '[QuantityPickerComponent.minusIcon] has not default value set in application configuration! Default value might not be suitable for current project.',
        'warn'
      )
      return QUANTITY_PICKER_COMPONENT_CONFIG_MAP.minusIcon
    }

    if (!this.config.hasOwnProperty('minusIcon')) {
      logger(
        '[QuantityPickerComponent.minusIcon] has not default value set in application configuration! Default value might not be suitable for current project.',
        'warn'
      )
      return QUANTITY_PICKER_COMPONENT_CONFIG_MAP.minusIcon
    }

    return this.config.minusIcon
  }

  public get configPlusIconName (): string | undefined {
    if (!this.config) {
      logger(
        '[QuantityPickerComponent.plusIcon] has not default value set in application configuration! Default value might not be suitable for current project.',
        'warn'
      )
      return QUANTITY_PICKER_COMPONENT_CONFIG_MAP.plusIcon
    }

    if (!this.config.hasOwnProperty('plusIcon')) {
      logger(
        '[QuantityPickerComponent.plusIcon] has not default value set in application configuration! Default value might not be suitable for current project.',
        'warn'
      )
      return QUANTITY_PICKER_COMPONENT_CONFIG_MAP.plusIcon
    }

    return this.config.plusIcon
  }

  public get isExceeded (): boolean {
    return !!this.qty && this.qty > this.maxQty
  }

  public get isMax (): boolean {
    return !!this.qty && this.qty === this.maxQty
  }

  /**
   * Executes when input loses focus.
   */
  public onBlur (event: FocusEvent): void {
    this.notify(Number((event.target as HTMLInputElement).value))
  }

  /**
   * Updates current quantity with new value.
   * @param newQuantity
   */
  public updateQuantity (newQuantity: number): void {
    this.notify(this.qty + newQuantity)
  }

  /**
   * Notify parent about quantity changes with new value.
   * @param quantity
   */
  private notify (quantity: number): void {
    let newQuantity = Number(quantity)

    if (quantity > this.maxQty) {
      newQuantity = this.maxQty
    }

    if (quantity < this.minQty) {
      newQuantity = this.minQty
    }

    this.$emit('onUpdate', newQuantity)
  }
}

export default QuantityPicker
