/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const QUANTITY_PICKER_COMPONENT_KEY = 'QuantityPickerComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface QuantityPickerComponentConfig {
  minusIcon: string
  plusIcon: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const QUANTITY_PICKER_COMPONENT_CONFIG_MAP: QuantityPickerComponentConfig = {
  minusIcon: 'MinusIcon',
  plusIcon: 'PlusIcon'
}
