




























































































import {
  Component,
  Inject as VueInject,
  Mixins,
  Prop,
  PropSync,
  ProvideReactive,
  Watch
} from 'vue-property-decorator'

import { ProductData } from '../../../../../contexts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { CartAnalyticsMixin } from '../../../../shared/mixins/cart-analytics.mixin'
import { CartViewMixin } from '../../../../shared/mixins/cartView.mixin'
import { DynamicContentData } from '../../../../shared/contracts/dynamicContent'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import Bundle from '../../../organisms/Bundle/Bundle.vue'
import { BundleProductsMixin } from '../../../shared/mixins/bundle-products.mixin'
import { CartCoupons } from '../../../organisms/CartCoupons'
import { CartSummary } from '../../../organisms/CartSummary'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItemEnhanced } from '../../../molecules/CartItem/CartItem.helpers'

import { ExtraItemsPicker } from '../partials/ExtraItemsPicker.vue'
import { PaymentOptions } from '../partials/PaymentOptions.vue'
import { PredictedDelivery } from '../partials/PredictedDelivery.vue'
import { CarouselConfig, CartViewConfig } from '../Cart.config'
import SuggestedProducts from '../partials/SuggestedProducts.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component<Enhanced>({
  name: 'Enhanced',
  components: {
    Bundle,
    CartCoupons,
    CartSummary,
    ExtraItemsPicker,
    PaymentOptions,
    PredictedDelivery,
    SidesLayout,
    SingleCartItem,
    FullscreenLoader,
    SuggestedProducts
  },
  async created (): Promise<void> {
    await this.composeBundles(this.bundledCartItems, this.cart)
  },
  mounted (): void {
    if (this.cart) {
      this.eventBus.emit('app:cart.view', this.getCartViewPayload(this.cart))
    }
  }
})
export class Enhanced extends Mixins<IToastMixin, IBaseCart, CartViewMixin, CartAnalyticsMixin, BundleProductsMixin>(
  ToastMixin,
  BaseCartMixin,
  CartViewMixin,
  CartAnalyticsMixin,
  BundleProductsMixin
) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  protected readonly config!: CartViewConfig

  @Prop({ type: Boolean, required: false })
  public readonly canPickGifts?: boolean

  @Prop({ type: Boolean, required: false })
  public readonly canPickSamples?: boolean

  @Prop({ type: Array, required: false })
  public readonly gifts?: Array<ProductData> | null

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasUnavailableCartItems!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDayHoliday!: boolean

  @Prop({ type: Array, required: false })
  public readonly samples?: Array<ProductData> | null

  @Prop({ type: Array, required: false })
  public readonly suggestedProducts?: Array<ProductData>

  @Prop({ type: Boolean, required: false })
  public readonly shouldDisablePerItemQuantity?: boolean

  @Prop({ type: Object, required: false, default: null })
  public readonly dynamicContentData!: DynamicContentData | null

  @PropSync('cartSuggestedRef', { required: false, default: null })
  public _cartSuggestedRef!: HTMLElement | null

  @ProvideReactive('cartDynamicContent')
  public get cartDynamicContent () {
    return this.dynamicContentData
  }

  @Watch('bundledCartItems')
  public async onBundlesUpdate () {
    await this.composeBundles(this.bundledCartItems, this.cart)
  }

  public get cartItems () {
    if (!this.cart) {
      return []
    }

    // Native .sort() causes bug with 'jumping' items
    const giftsAndSamples = this.cart.items.filter((item) => item.isGift || item.isSample)
    const regularItems = this.cart.items.filter((item) => !item.isGift && !item.isSample && !item.isInBundle)

    return regularItems.concat(giftsAndSamples ?? [])
  }

  public get isPresaleInCart (): boolean {
    return !!this.cart && this.cart.items.some((item) => item.isPresale)
  }

  public get paymentIcons (): string[] {
    if (!this.config.hasOwnProperty('cartIcons')) {
      return []
    }

    if (this.config.cartIcons.length === 0) {
      return []
    }

    return this.config.cartIcons
  }

  public get carouselConfig (): CarouselConfig {
    return this.config.carouselConfig
  }

  public get hasIncorrectQuantities (): boolean {
    if (!this.cart) {
      return true
    }

    return this.cart.hasIncorrectQuantities()
  }

  public onItemResign (): void {
    this.$emit('onItemResign')
  }

  public onItemSelect (value: { product: ProductData | null }): void {
    this.$emit('onItemSelect', value)
  }

  public onUpdateCartRequest () {
    if (this.cart) {
      /**
       * FIXME: available this.refreshCart() method does not working properly
       */
      window.location.reload()
    }
  }

  public translateToCartItem = translateToCartItemEnhanced
}

export default Enhanced
