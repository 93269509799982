
import { Component, Vue } from 'vue-property-decorator'

import { AllowedAttributes, CartItemData, ProductData } from '../../../../contexts'
import { CandidateBundle, CartBundleItem, CartBundleSibling } from '../../../../contexts/checkout/contracts/bundles'
import { Inject, logger } from '../../../../support'

import { BundledItemProps } from '../../../shared/molecules/BundledItem'
import { IProductsRepository, ProductsRepositoryType } from '../../../products/contracts/repositories'

import { CartModel, CartMutationTypes } from '../../contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'BundleProductsMixin'
})
export class BundleProductsMixin extends Vue {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public composedBundles: Array<CartBundleItem> = []

  /**
   * Method that assembles bundles props from bundled-aware cart
   * @param bundledCartItems
   * @param cart
   */
  public async composeBundles (bundledCartItems: Array<CartItemData>, cart: CartModel | null): Promise<void> {
    const bundles = new Map()

    for (const bundle of bundledCartItems) {
      if (!bundle.bundleSiblings) {
        break
      }

      const commonParentSku = bundle.bundleSiblings[0].parentSku

      const currentProductSibling = {
        outOfStock: bundle.outOfStock ?? true,
        parentSku: commonParentSku,
        product: bundle.product as ProductData,
        quantity: bundle.quantity,
        uid: bundle.uid,
        sku: bundle.sku
      }
      const candidateChildren = bundle.bundleSiblings.concat(currentProductSibling)

      const bundledItemChildren = BundleProductsMixin.composeBundleChildren(candidateChildren, cart)
      const isBundleAvailable = candidateChildren.every((child) => !child.outOfStock)
      const bundleRegularPrice = bundledItemChildren.reduce((acc, currentChild) => acc + currentChild.price, 0)
      const bundleDiscount = bundledItemChildren.reduce((acc, currentChild) => acc + currentChild.discount, 0)

      bundles.set(commonParentSku, {
        bundledProductSku: commonParentSku,
        children: bundledItemChildren,
        isAvailable: isBundleAvailable,
        prices: {
          regularPrice: bundleRegularPrice,
          discount: bundleDiscount,
          finalPrice: bundleRegularPrice - bundleDiscount,
          previousPrice: undefined
        },
        quantity: bundle.quantity
      })
    }

    const parentsSkus = [...bundles.keys()]

    if (bundles.size > 0) {
      try {
        const parentsData = await this.productsRepository.loadProductsBySkus(
          // Note: API will return 'parent' sku as e.g. 'parent_100530905' / 'parent_100530905_<something>'
          parentsSkus.map((sku) => sku.split('_')[1].split('_')[0])
        )

        this.composedBundles = BundleProductsMixin.composeBundlesData([...bundles.values()], parentsData)
        this.$store.commit(CartMutationTypes.SetBundles, this.composedBundles)
      } catch (e) {
        logger(e, 'warn')
      }
    }
  }

  /**
   * Composes bundle children data
   * @param candidateChildren
   * @param cart
   * @private
   */
  private static composeBundleChildren (candidateChildren: Array<CartBundleSibling>, cart: CartModel | null): Array<BundledItemProps> {
    return candidateChildren.map((child) => {
      const variant = Object.values(child.product.variants)[0]
      const cartItem = cart?.items.find((item) => item.uid === child.uid && item.isInBundle)
      return {
        isAvailable: variant.isAvailable,
        sellableQuantity: variant.sellableQuantity,
        sku: variant.sku,
        uid: child.uid,
        price: cartItem ? cartItem.prices.total.value : 0,
        discount: (cartItem && cartItem.prices.itemDiscount) ? cartItem.prices.itemDiscount.value : 0,
        product: child.product
      }
    })
  }

  /**
   * Composes final bundle data
   * @param candidateBundles
   * @param parentsData
   * @private
   */
  private static composeBundlesData (candidateBundles: Array<CandidateBundle>, parentsData: Array<ProductData>): Array<CartBundleItem> {
    return candidateBundles.map((bundle) => {
      let foundParentData
      const children = bundle.children.map((child: BundledItemProps) => Object.values(child.product.variants)[0].sku)

      for (const parent of parentsData) {
        const parentVariants = Object.values(parent.variants)
        foundParentData = parentVariants.filter((variant) => {
          if (!variant.attributes[AllowedAttributes.BundledProducts]) {
            return false
          }
          return children.filter((child: string) =>
            !(variant.attributes[AllowedAttributes.BundledProducts] as string[]).includes(child)).length === 0
        })

        if (foundParentData && foundParentData.length > 0) {
          break
        }
      }

      const composedParent = {
        children: bundle.children,
        isAvailable: bundle.isAvailable,
        prices: bundle.prices,
        quantity: bundle.quantity,
        sku: bundle.bundledProductSku
      }

      return foundParentData ? {
        ...composedParent,
        bundledProduct: foundParentData[0],
        prices: {
          ...composedParent.prices,
          previousPrice: foundParentData[0].price.previousPrice
        }
      } : {
        ...composedParent,
        bundledProduct: undefined
      }
    })
  }
}
