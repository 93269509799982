





























import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  AllowedAttributes,
  AllowedImageRatio,
  AllowedImageWidth,
  Variant
} from '../../../../contexts'

import { BundledItemProps } from './BundledItem.contracts'
import { toImageProps } from '../../support'
import { ImageProps } from '../../../../dsl/atoms/Image'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'BundledItem'
})
export class BundledItem extends Vue {
  @Prop({ type: Boolean, required: true, default: false })
  public readonly isAvailable!: BundledItemProps['isAvailable']

  @Prop({ type: Number, required: true, default: 0 })
  public readonly sellableQuantity!: BundledItemProps['sellableQuantity']

  @Prop({ type: String, required: true, default: null })
  public readonly sku!: BundledItemProps['sku']

  @Prop({ type: Number, required: true, default: null })
  public readonly price!: BundledItemProps['price']

  @Prop({ type: Object, required: true, default: null })
  public readonly product!: BundledItemProps['product']

  public get brand (): string | undefined {
    if (!this.variant) {
      return
    }

    const brand = this.variant.attributes[AllowedAttributes.Brand] as string
    return brand ?? undefined
  }

  public get brandUrl (): string | undefined {
    if (!this.variant) {
      return
    }

    const brandUrl = this.variant.attributes[AllowedAttributes.BrandUrl] as string
    return brandUrl ?? undefined
  }

  public get category (): string | undefined {
    if (!this.variant) {
      return
    }

    const category = this.variant.attributes[AllowedAttributes.MainCategory] as string
    const volume = this.variant.attributes[AllowedAttributes.VolumeName] as string
    return category ? `${category}${volume ? ' ' + volume : ''}` : undefined
  }

  public get image (): ImageProps | undefined {
    if (!this.product) {
      return
    }

    return toImageProps(
      Object.values(this.product.variants).filter((variant) => variant.sku === this.sku)[0].images[0],
      AllowedImageRatio.Square,
      AllowedImageWidth.ProductCardSmall
    )
  }

  public get variant (): Variant<string> | undefined {
    return Object.values(this.product.variants).filter((variant) => variant.sku === this.sku)[0] ?? undefined
  }

  public get variantUrl (): string | undefined {
    if (!this.variant) {
      return
    }

    return this.variant.link
  }
}

export default BundledItem
