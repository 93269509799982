





































































import { Component, Prop } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../../contexts'
import { BundledItem } from '../../../shared/molecules/BundledItem'
import { CartBundleItem } from '../../../../contexts/checkout/contracts/bundles'
import { ToastMixin } from '../../../shared'

import { Action as CartItemActions } from '../../molecules/CartItem'
import { CartItemAction } from '../../molecules/CartItem/CartItem.config'
import QuantityPicker from '../../molecules/QuantityPicker/QuantityPicker.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CartBundle>({
  name: 'CartBundle',
  components: { BundledItem, QuantityPicker }
})
export class CartBundle extends ToastMixin {
  @Prop({ type: Object, required: true })
  public readonly bundle!: CartBundleItem

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCheckout!: boolean

  @Prop({ required: false, type: Function })
  public quantityCallback!: (newQuantity: number) => Promise<boolean> | boolean

  public quantityPickerReload: number = 0
  public BUNDLE_MIN_QUANTITY: number = 0
  public BUNDLE_MAX_QUANTITY: number = 2000
  public BUNDLE_COUNTER: number = 1
  public isLoading: boolean = false
  public toggled: boolean = false

  public get bundleCategory (): string | undefined {
    if (!this.bundle.bundledProduct) {
      return
    }
    return this.bundle.bundledProduct.attributes[AllowedAttributes.MainCategory] as string ?? undefined
  }

  /**
   * Determines actions of cart item.
   */
  public get deleteAction (): CartItemActions[] {
    return [
      {
        label: this.$t('front.checkout.organisms.SingleCartItem.actions.delete.label').toString(),
        key: CartItemAction.Delete,
        icon: {
          color: '#5F5F5F',
          height: '1em',
          name: 'TrashIcon',
          width: '1em'
        },
        onClick: () => this.onUpdate(0, this.bundle)
      }
    ]
  }

  public get hasChildren (): boolean {
    return Array.isArray(this.bundle.children) && this.bundle.children.length > 0
  }

  public get hasQuantityCallback (): boolean {
    return typeof this.quantityCallback !== 'undefined'
  }

  public get sellableQuantity (): number {
    return Math.min(...this.bundle.children.map(item => item.sellableQuantity))
  }

  public get isOutOfStock (): boolean {
    return this.bundle.children.some(item => item.outOfStock)
  }

  public onUpdate (newQuantity: number, bundle: CartBundleItem): void {
    const canUpdateQuantity = this.checkIfCanUpdateQuantity(newQuantity, bundle)
    if (!canUpdateQuantity) {
      return
    }

    this.isLoading = true

    const response: Promise<boolean> | boolean = this.quantityCallback(newQuantity)
    if (typeof response === 'object' && typeof response.then === 'function') {
      response
        .then()
        .catch((e: Error) => {
          this.showToast(e.message, 'danger')
        }).finally(() => {
          this.isLoading = false
        })
    }
  }

  public onToggle (): void {
    this.toggled = !this.toggled
  }

  private checkIfCanUpdateQuantity (quantity: number, bundle: CartBundleItem): boolean {
    const canUpdateQuantity = bundle.children.every(child => child.sellableQuantity >= quantity)

    if (!canUpdateQuantity && this.toastDefaultOptionsConfig) {
      this.showToast(this.$t('front.checkout.views.CartView.bundles.quantityError').toString(), 'danger', '', {
        ...this.toastDefaultOptionsConfig,
        duration: 5000,
        dismissible: true
      })
      return false
    }

    return true
  }
}

export default CartBundle
